.section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: gray;
    padding: 50px;
    height: 80vh;
}

.bio {
    display: flex;
    flex-direction: column;
}

.name-text {
    font-size: 50px;
}

.small-text {
    font-size: 20px;
}

.highlight-text {
    color: orange;
}

.profile-image {
    flex: 1;
    width: 25vw;
    border-radius: 50%;
    border: 5px solid orange;
}

.icons {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    justify-content: space-evenly;
}